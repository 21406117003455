/**
 * Internal dependencies
 */
import { getPaymentForms } from '@wpsimplepay/pro/frontend/payment-forms';
import { onPaymentFormError } from '@wpsimplepay/core/frontend/payment-forms';

/**
 * Sets up a Stripe Elements Card field and binds events.
 *
 * @link https://stripe.com/docs/stripe-js/reference#elements-create
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export function setup( spFormElem, formData ) {
	const cardEl = spFormElem[ 0 ].querySelector( '.simpay-card-wrap' );

	if ( ! cardEl ) {
		return;
	}

	const { enableForm } = window.simpayApp;

	// General Elements helpers from the Payment Form.
	const { 'stripe-elements': stripeElements } = getPaymentForms();
	const { getElementStyle } = stripeElements;

	// Access Elements instance.
	const { stripeInstance: { elements } } = spFormElem;

	// If a billing address field exists (overrides Card field setting).
	const hidePostalCode =
		!! spFormElem[ 0 ].querySelector( '.simpay-address-zip' ) ||
		'no' === cardEl.dataset.showPostalCode;

	// Create Element Card instance.
	elements.card = elements().create( 'card', {
		style: getElementStyle( spFormElem, cardEl ),
		hidePostalCode,
	} );

	// Mount and setup Element card instance.
	elements.card.mount( cardEl );

	// Live feedback when card updates.
	elements.card.on( 'change', ( { error } ) => {
		if ( error ) {
			onPaymentFormError( error, spFormElem, formData );
		} else {
			onPaymentFormError( {}, spFormElem, formData );
		}
	} );
}
