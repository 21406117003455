/**
 * Update the form amount based on "Amount" field selected value.
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export function update( e, spFormElem, formData ) {
	// Don't update for Subscriptions which can be properly
	// configured without an "Amount" field.
	const { isSubscription } = formData;

	if ( true === isSubscription ) {
		return;
	}

	const {
		convertToCents,
		convertToDollars,
		formatCurrency,
	} = window.spShared;

	let amount = convertToDollars( spFormElem.cart.getLineItem( 'base' ).amount );

	// Update the amount to the selected dropdown amount
	if ( 0 !== spFormElem.find( '.simpay-amount-dropdown' ).length ) {
		amount = spFormElem.find( '.simpay-amount-dropdown' ).find( 'option:selected' ).data( 'amount' );

		spFormElem.trigger( 'simpayDropdownAmountChange' );
	// Update the amount to the selected radio button
	} else if ( 0 !== spFormElem.find( '.simpay-amount-radio' ).length ) {
		amount = spFormElem.find( '.simpay-amount-radio' ).find( 'input[type="radio"]:checked' ).data( 'amount' );

		spFormElem.trigger( 'simpayRadioAmountChange' );
	}

	const customAmountInput = spFormElem.find( '.simpay-custom-amount-input' );
	const errorEl = spFormElem.find( '.simpay-errors' );

	if ( customAmountInput.length ) {
		customAmountInput.val( formatCurrency( amount, false ) );
		customAmountInput.removeClass( 'simpay-input-error' );
		errorEl.empty();
	}

	if ( amount > 0 ) {
		try {
			const item = spFormElem.cart.getLineItem( 'base' );

			item.update( {
				amount: convertToCents( amount ),
			} );

			// Backwards compatibility.
			formData.amount = amount;

			// Alert the rest of the components they need to update.
			spFormElem.trigger( 'totalChanged', [ spFormElem, formData ] );
		} catch ( error ) {
			// Error is logged, UI does not need updating.
		}
	}
}

/**
 * DOM ready.
 *
 * @param {jQuery} $ jQuery.
 */
( function( $ ) {
	/**
	 * Bind when Payment Form is ready.
	 *
	 * @param {Object} e Event
	 * @param {Object} spFormElem Form element.
	 * @param {Object} formData Form data.
	 */
	$( document.body ).on( 'simpayBindCoreFormEventsAndTriggers', ( e, spFormElem, formData ) => {
		// Update amounts on load.
		update( e, spFormElem, formData );

		/**
		 * Update amounts when a "Quantity" input changes.
		 *
		 * @param {Event} e Change event.
		 */
		spFormElem
			.find( '.simpay-amount-dropdown, .simpay-amount-radio' )
			.on( 'change', ( e ) => update( e, spFormElem, formData ) );
	} );
} ) ( jQuery );
