/**
 * Internal dependencies
 */
import { getPaymentMethod } from '@wpsimplepay/pro/frontend/payment-methods';
import { onPaymentFormError } from '@wpsimplepay/core/frontend/payment-forms';

/**
 * Submits a Stripe Elements payment form.
 *
 * @todo DRY presubmission validation checks?
 *
 * @since 3.8.0
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export function submit( spFormElem, formData ) {
	const {
		disableForm,
		enableForm,
		triggerBrowserValidation,
	} = window.simpayApp;

	// Disable form while processing.
	disableForm( spFormElem, formData, true );

	// HTML5 validation check.
	if ( ! spFormElem[ 0 ].checkValidity() ) {
		triggerBrowserValidation( spFormElem, formData );
		enableForm( spFormElem, formData );

		return;
	}

	// Allow further validation.
	//
	// jQuery( document.body ).on( 'simpayBeforeStripePayment', function( e, spFormElem, formData ) {
	//  formData.isValid = false;
	// } );
	spFormElem.trigger( 'simpayBeforeStripePayment', [ spFormElem, formData ] );

	if ( ! formData.isValid ) {
		enableForm( spFormElem, formData );

		return;
	}

	try {
		getPaymentMethod( spFormElem.paymentMethod )
			.submit( spFormElem, formData );
	} catch ( error ) {
		onPaymentFormError( error, spFormElem, formData );
	}
}
