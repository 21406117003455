/**
 * Internal dependencies
 */
import { default as card } from './card';
import { default as achDebit } from './ach-debit';
import { default as ideal } from './ideal';

/**
 * Returns available Payment Methods.
 *
 * @since 3.8.0
 *
 * @return {Object} List of available Payment Methods.
 */
export const getPaymentMethods = () => ( {
	card,
	'ach-debit': achDebit,
	ideal,
} );

/**
 * Returns a Payment Method.
 *
 * @since 3.8.0
 *
 * @param {string} paymentMethod Payment Method ID.
 * @return {false|Object} Payment Method object if available, otherwise false.
 */
export const getPaymentMethod = ( paymentMethod ) => {
	const paymentMethods = getPaymentMethods();

	if ( false === paymentMethods.hasOwnProperty( paymentMethod ) ) {
		return false;
	}

	return paymentMethods[ paymentMethod ];
};
