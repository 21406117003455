/**
 * Internal dependencies
 */
import { getPaymentForms as corePaymentForms } from '@wpsimplepay/core/frontend/payment-forms';
import { default as stripeElements } from './stripe-elements';

/**
 * Returns available Payment Forms.
 *
 * @since 3.8.0
 *
 * @return {Object} List of available Payment Forms.
 */
export const getPaymentForms = () => ( {
	...corePaymentForms(),
	'stripe-elements': stripeElements,
} );
