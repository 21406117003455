/**
 * WordPress dependencies.
 */
import { addQueryArgs } from '@wordpress/url';

/**
 * Internal dependencies.
 */
import { getPaymentForms } from '@wpsimplepay/pro/frontend/payment-forms';
import { getPaymentMethod } from '@wpsimplepay/pro/frontend/payment-methods';
import { create as createCustomer } from '@wpsimplepay/core/frontend/payments/customer.js';
import {
	create as createPaymentIntent,
	handleServerResponse as handlePaymentIntentServerResponse,
} from '@wpsimplepay/pro/frontend/payments/paymentintent.js';
import {
	create as createSubscription,
	handleServerResponse as handleSubscriptionServerResponse,
} from '@wpsimplepay/pro/frontend/payments/subscription.js';

/**
 * Handle iDEAL Payment Method.
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export async function submit( spFormElem, formData ) {
	// General Elements helpers.
	const { 'stripe-elements': stripeElements } = getPaymentForms();
	const { getOwnerData, onError } = stripeElements;

	try {
		const iDEAL = getPaymentMethod( 'ideal' );

		// Access Stripe and Elements instance.
		const { stripeInstance: stripe, stripeInstance: { elements } } = spFormElem;

		// Bail if no Bank has been chosen.
		if ( false === elements.ideal._complete ) {
			const { config: { i18n } } = iDEAL;

			throw {
				message: i18n.empty,
			};
		}

		// Create a Customer.
		const customer = await createCustomer( {}, spFormElem, formData );

		const successUrl = addQueryArgs( formData.stripeParams.success_url, {
			customer_id: customer.id,
		} );

		// Create a PaymentIntent.
		const paymentIntent = await createPaymentIntent(
			{
				customer_id: customer.id,
				payment_method_type: 'ideal',
			},
			spFormElem,
			formData
		);

		// Handle next actions on PaymentIntent.
		const paymentIntentRequiresAction = await handlePaymentIntentServerResponse(
			{
				customer_id: customer.id,
				payment_intent: paymentIntent,
			},
			spFormElem,
			formData
		);

		// Nothing else is needed, create the iDEAL Source.
		if ( false === paymentIntentRequiresAction ) {
			stripe
				.confirmIdealPayment( paymentIntent.payment_intent_client_secret, {
					payment_method: {
						ideal: elements.ideal,
						billing_details: getOwnerData( spFormElem, formData ),
					},
					return_url: successUrl,
				} )
				.then( ( { error } ) => {
					if ( error ) {
						onError( error, spFormElem, formData );
					}
				} );
		}
	} catch ( error ) {
		onError( error, spFormElem, formData );
	}
}
