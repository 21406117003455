/**
 * Internal dependencies.
 */

import { getPaymentForms } from '@wpsimplepay/pro/frontend/payment-forms';
import { handle as handleSource } from '@wpsimplepay/pro/frontend/payments/source.js';

/**
 * Submit `card` Payment Method.
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export async function submit( spFormElem, formData ) {
	const {
		stripeInstance: stripe,
		stripeInstance: { elements },
	} = spFormElem;

	// General Elements helpers.
	const { 'stripe-elements': stripeElements } = getPaymentForms();
	const { getOwnerData, onError } = stripeElements;

	// Create a Source.
	const { currency } = formData;
	const owner = getOwnerData( spFormElem, formData );

	const { error, source } = await stripe.createSource( elements.card, {
		type: 'card',
		currency,
		owner,
	} );

	try {
		// Bail if Source cannot be created.
		if ( error ) {
			throw error;
		}

		// Create records with Source.
		handleSource( source, spFormElem, formData );
	} catch ( error ) {
		onError( error, spFormElem, formData );
	}
}
