/**
 * Internal dependencies
 */
import { getPaymentForms } from '@wpsimplepay/pro/frontend/payment-forms';

/**
 * Sets up a Stripe Elements iDEAL field and binds events.
 *
 * @link https://stripe.com/docs/stripe-js/reference#elements-create
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export function setup( spFormElem, formData ) {
	const idealEl = spFormElem[ 0 ].querySelector( '.simpay-ideal-wrap' );

	if ( ! idealEl ) {
		return;
	}

	// General Elements helpers.
	const { 'stripe-elements': stripeElements } = getPaymentForms();
	const { getElementStyle } = stripeElements;

	// Access Stripe and Elements instance.
	const { stripeInstance: { elements } } = spFormElem;

	// Create Element iDEAL instance.
	elements.ideal = elements().create( 'idealBank', {
		style: {
			...getElementStyle( spFormElem, idealEl ),
			base: {
				// Add extra padding for dropdown.
				padding: '10px',
				...getElementStyle( spFormElem, idealEl ).base,
			},
		},
	} );

	// Mount and setup Element iDEAL instance.
	elements.ideal.mount( idealEl );
}
