/**
 * Internal dependencies.
 */
import { setup } from './setup.js';
import { submit } from './submit.js';

export default {
	setup,
	submit,
};
